import { forwardRef, useState } from 'react';
import { TODO } from '../../types';
import { FaEye, FaEyeSlash } from 'react-icons/fa6';
import { twMerge } from 'tailwind-merge';
import { FormError } from '../FormError';

type TSizeVariants = 'sm' | 'md';

const sizeStyles: Record<TSizeVariants, string> = {
  sm: 'py-4 px-4 text-sm',
  md: 'py-5 px-3 text-base',
};

interface IInput {
  register: TODO;
  name: string;
  sizeVariant?: TSizeVariants;
  error?: string;
  hasError?: boolean;
}

type TInputProps = IInput & React.InputHTMLAttributes<HTMLInputElement>;

const Input = forwardRef<HTMLInputElement, TInputProps>(
  (
    {
      name,
      placeholder,
      className,
      disabled,
      defaultValue,
      onChange,
      register,
      type = 'text',
      required = false,
      sizeVariant,
      error,
      hasError,
    },
    ref,
  ) => {
    const [showPasswordState, setShowPasswordState] = useState(false);
    const toggleShowPassword = () => setShowPasswordState(!showPasswordState);
    const renderType = type === 'password' && showPasswordState ? 'text' : type;
    const errorStyles = error || hasError ? 'border-red-500' : '';

    return (
      <>
        <div className="relative">
          <input
            ref={ref}
            type={renderType}
            name={name}
            placeholder={placeholder}
            defaultValue={defaultValue}
            onChange={onChange}
            disabled={disabled}
            className={twMerge(
              `bg-[#F9FAFB] text-text w-full rounded-xl border border-[#F9FAFB] ${errorStyles} outline-primary transition placeholder:text-gray-600 hover:border-primary disabled:cursor-not-allowed disabled:border-0 relative`,
              sizeStyles[sizeVariant || 'md'],
              className,
            )}
            {...register(name, { required: required })}
          />
          {type === 'password' && (
            <div
              className={`absolute top-2/4 -translate-y-2/4 right-9 ${sizeVariant === 'sm' ? 'scale-75' : ''}`}
            >
              {showPasswordState ? (
                <FaEyeSlash size={24} onClick={toggleShowPassword} />
              ) : (
                <FaEye size={24} onClick={toggleShowPassword} />
              )}
            </div>
          )}
        </div>
        <FormError>{error}</FormError>
      </>
    );
  },
);

export default Input;
